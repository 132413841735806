import '@pexip/components/src/fonts.css';
import '@pexip/components/dist/style.css';
import '@pexip/media-components/dist/style.css';

import React from 'react';
import {createRoot} from 'react-dom/client';
import {ErrorBoundary} from '@sentry/react';

import './polyfills';

import {DEFAULT_SUPPORTED_LANGUAGES, initI18next} from './i18n';
import {initialLog} from './initialLog';
import {logger} from './logger';
import {config} from './config';
import {router} from './router';
import {setupSentry} from './sentry';
import {App} from './App';
import {Error} from './pages/Error.page';

initialLog();
setupSentry();
void initI18next(DEFAULT_SUPPORTED_LANGUAGES);

router.urlChangedSignal.add(url => {
    logger.debug({url}, 'Url changed');
});
router.urlRequestSignal.add(url => {
    logger.debug({url}, 'request a new Url');
});

// loadConfigFromQuery is off for security reasons:
const loadConfigFromQuery = false;
config.load({}, loadConfigFromQuery);

// getElementById can be potentially null, but not worth handling, too broken if it is
const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
    <ErrorBoundary
        fallback={({resetError: reload}) => <Error reload={reload} />}
    >
        <App />
    </ErrorBoundary>,
);
